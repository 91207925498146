import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import styled, { keyframes } from 'styled-components';
import Row from 'react-bootstrap/Row';
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import mainpgif from '../images/mainpggif.gif'
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import LanguageIcon from '@mui/icons-material/Language';
import WatchIcon from '@mui/icons-material/Watch';
import TvIcon from '@mui/icons-material/Tv';
import DeveloperBoardIcon from '@mui/icons-material/DeveloperBoard';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import EmailIcon from '@mui/icons-material/Email';
import SendIcon from '@mui/icons-material/Send';
import PhoneEnabledIcon from '@mui/icons-material/PhoneEnabled';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import BugReportIcon from '@mui/icons-material/BugReport';
import CurrencyBitcoinIcon from '@mui/icons-material/CurrencyBitcoin';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import CoPresentIcon from '@mui/icons-material/CoPresent';
import whychoseus from "../images/whychoseus.png"
import { FormEvent, useRef } from 'react';
import emailjs from '@emailjs/browser';
import Aos from 'aos'
import 'aos/dist/aos.css'





const useStyles = makeStyles((theme) => ({
    customIconSizes: {
        fontSize: '75px',
    },
    editedcustomSize: {
      fontSize: '55px',
      marginTop: '15px',
      marginRight: '5px',
      marginLeft: '15px',
    },
    customSize: {
      fontSize: '30px',
    },
    socialIcon: {
        fontSize: '50px',
        marginLeft: '5px',
        marginRight: '15px',
    },
    serviceIcon: {
        marginBottom: '70px',
        marginRight: '0px',
    },
  }));


const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const TextContainer = styled.div`
  opacity: 0;
  animation: ${fadeIn} 6s linear infinite;
  position: relative;
  z-index: -1;
`;


export const Main = () => {

    useEffect(() => {
        Aos.init({duration:3000})
    }, [])

    const [texts, setTexts] = useState(['Boost Your Online Growth with Tech', 'Information Technology Consultant Company', 'Transform Your Ideas Into Seamless Digital Experience']);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [animationKey, setAnimationKey] = useState(0);
    const classes = useStyles();

    useEffect(() => {
        const timer = setTimeout(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % texts.length);
        setAnimationKey((prevKey) => prevKey + 1);
        }, 6000);

        return () => clearTimeout(timer);
    }, [currentIndex, texts]);

    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_cjv9jr5', 'template_itmkrxb', form.current, 'D-H7wbWhWoPa_n4ED')
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });
    };


    return(
        <div>
            <Container>
                <Row>
                    <Col className='mainmainhldr'>
                        <div className='mainmaintxthlrd'>
                            <TextContainer key={animationKey}>
                                <h1 className='mainmaintxt'>{texts[currentIndex]}</h1>
                            </TextContainer>
                            <div>
                                <p className='mainmidtxt'>We are an elite team of professional software engineers and branding specialists with several years of experience in delivery of superior products</p>
                            </div>
                        </div>
                        <div className='mainpicdiv'>< img src={mainpgif}  className='mainpic'/></div>
                    </Col>
                </Row>
                <Row>
                    <Col className=''>
                        <div className='midbdy'>
                            <div className='hderhldr'><h2 className='midhder'>We Deliver a variety of Solutions to fit your specific needs</h2></div>
                            <div className='midbdymid'>
                                <div  className='mjukol'><LanguageIcon style={{fontSize:'55px'}} /><p className={classes.customSize}>Web</p></div>
                                <div className='mjukol'><PhoneAndroidIcon style={{fontSize:'55px'}} /><p className={classes.customSize}>Android</p></div>
                                <div className='mjukol'><PhoneIphoneIcon style={{fontSize:'55px'}} /><p className={classes.customSize}>IOS</p></div>
                                <div className='mjukol'><DeveloperBoardIcon style={{fontSize:'55px'}} /><p className={classes.customSize}>IOT</p></div>
                                <div className='mjukol'><WatchIcon style={{fontSize:'55px'}}/><p className={classes.customSize}>Wearables</p></div>
                                <div className='mjukol'><TvIcon style={{fontSize:'55px'}} /><p className={classes.customSize}>TV</p></div>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className='rectngle'>
                    <Col>
                        <div className='mainservichldr'>
                            <h2>We Offer A Wide Variety of IT Services</h2>
                            <div className='servicehldr'>
                                <div>
                                    <div   className='minmainone'>
                                        <div><LanguageIcon style={{fontSize: '50px'}} className={classes.serviceIcon}/></div>
                                        <div className='mainvil'>
                                            <h3 className='midhder midhderweb'>Web Development</h3>
                                            <p className='minvil'>Our distinction goes beyond mere coding proficiency. It's our extensive experience that sets us apart in the field of web development.</p>
                                        </div>
                                    </div>
                                </div>
                                <div >
                                    <div className='minmainone'>
                                        <div><PhoneIphoneIcon style={{fontSize: '50px'}} className={classes.serviceIcon}/></div>
                                        <div className='mainvil'>
                                            <h3 className='midhder midhdermbile'>Mobile Development</h3>
                                            <p className='minvil' >Build a robust brand with tailored mobile apps that meet market requirements. Develop enterprise software for reliable integration and legacy system modernization.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='servicehldr'>
                                <div >
                                    <div className='minmainone'>
                                        <div><CoPresentIcon  className={classes.serviceIcon} style={{fontSize: '50px'}}/></div>
                                        <div className='mainvil'>
                                            <h3 className='midhder midhderconsul' >IT Consultancy</h3>
                                            <p className='minvil'>Leverage our extensive IT expertise for efficient and cost-effective consulting. Trust our top minds to streamline workflows, implement new tech, and consolidate app portfolios.</p>
                                        </div>
                                    </div>
                                </div>
                                <div >
                                    <div className='minmainone'>
                                        <div><CurrencyBitcoinIcon style={{fontSize: '50px'}} className={classes.serviceIcon}/></div>
                                        <div className='mainvil'>
                                            <h3 className='midhder midhderblkchn'>Blockchain Development</h3>
                                            <p className='minvil' >Create secure and transparent Blockchain applications. Dedicated to enhancing and building top-tier blockchain products for everyday use.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='servicehldr'>
                                <div  >
                                    <div  className='minmainone'>
                                        <div><BugReportIcon style={{fontSize: '50px'}} className={classes.serviceIcon}/></div>
                                        <div className='mainvil'>
                                            <h3 className='midhder midhderqatst' >QA & Testing</h3>
                                            <p className='minvil' >Count on our seasoned experts to conduct in-depth and thorough software testing and auditing, ensuring the reliability and quality of your software solutions.</p>
                                        </div>
                                    </div>
                                </div>
                                <div >
                                    <div className='minmainone'>
                                        <div><DesignServicesIcon style={{fontSize: '50px'}} className={classes.serviceIcon}/></div>
                                        <div className='mainvil'>
                                            <h3 className='midhder midhderdsgn'>Design Solutions</h3>
                                            <p className='minvil'>We excel in crafting standout, user-friendly software. Our UI/UX designers specialize in creating brand identity concepts and exceptional interfaces.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div><h2 className='whychse'>Why Choose Us</h2></div>
                        <div>
                            <img data-aos='zoom-in' src={whychoseus} className='kalme' />
                        </div>
                        <div className='chsehldr'>
                            <div className='klmetxthldr'>
                                <p className='klmetxt'>Qpid as an Organization is built to provide top-notch IT Consulting, QA & Testing, Blockchain development, Website and Mobile Application Development, Data Analysis, and Cybersecurity services.</p>
                                <p className='klmetxt'>Our focus extends beyond these services we're catalysts for social change, bringing forth ideas and resources. What sets us apart is our meticulous attention to detail, adherence to timelines, and adept project management skills.</p>
                                <p className='klmetxt'>Creative, fast, reliable, convenient, efficient, and accountable — these qualities define us. We assemble the right minds, fostering critical thinking for transformative outcomes. Collaborating with our clients, we build capabilities that ensure sustainable advantages for organizations.</p>
                            </div>
                            <div className='prdprcshldr'>
                                <h3 className='prdprcs'>Qpid's standard Production Process</h3>
                                <div>
                                    <ol className='litemhldr'>
                                        <li className='litem'>Requirement Gathering: We follow the first and foremost priority of gathering requirements, resources, and information to begin any project.</li>
                                        <li className='litem'>Design & Development: We specialize in crafting captivating and user-friendly designs, employing the latest design tools to ensure a superior user experience. Additionally, our expertise extends to the development of transparent mobile, web, and blockchain solutions, leveraging cutting-edge technologies for innovative and effective outcomes.</li>
                                        <li className='litem'>Quality Assurance Testing: Qpid places a premium on quality, ensuring the delivery of bug-free applications without compromise. Following extensive trials and meticulous processes, your application is meticulously prepared for a successful launch.</li>
                                        <li className='litem' >Deployment: Qpid is committed to ensuring the utmost quality in our applications, providing a seamless and bug-free experience. Through rigorous testing and meticulous processes, your app is meticulously prepared for deployment on servers, the App Store, or Play Store, guaranteeing a reliable and successful launch.</li>
                                        <li className='litem'>Support & Maintenance: Our company is dedicated to providing ongoing support, with our team standing ready to address any queries even after the deployment phase.</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col className='btmhldrfin'>
                        <div className='lstftm'>
                            <div><p className='endmaymantxt'>Do you have questions?</p><h2 className='endmaymaintxt'>Reach out to us now</h2></div>
                            <div>
                                <div className='midend'><div className='socoils'><EmailIcon className={classes.editedcustomSize}/><p className='endmaytxt' >info@qpidsprofessionals.com</p></div>  <div className='socoils'><PhoneEnabledIcon className={classes.editedcustomSize} /><p className='endmaytxt'>+234-913-952-6519</p></div></div>
                                <div><a href='https://x.com/qprofessionals_?s=21' target='_blank'><TwitterIcon style={{color:'white'}} className={classes.socialIcon}/></a><a href='https://instagram.com/qpidsprofessionals?igshid=MzMyNGUyNmU2YQ==' target='_blank'><InstagramIcon style={{color:'white'}} className={classes.socialIcon}/></a></div>
                                <div className='btmend'>
                                    <h3>Subscribe to Qpid's Tech News Letter</h3>
                                    <form className='frmfull' ref={form} onSubmit={sendEmail}>
                                        <Form.Group>
                                            <input name='user_email' placeholder='Input Your Email Here' className='frmmain'/>
                                        </Form.Group>
                                        <button type="submit" value="Send" className='btmsub' style={{fontSize: '8px', backgroundColor:'white', marginLeft:'5px', borderRadius:'10px', width:'40px'}}><SendIcon style={{fontSize: '15px', color:'#643384'}} /></button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}