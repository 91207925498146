import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import styled, { keyframes } from 'styled-components';
import Row from 'react-bootstrap/Row';
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import resturant from '../images/Coffee.png'
import ecommerce from '../images/Ecommerceweb.png'
import blockchin from '../images/cryptobin.jpg'
import finance from '../images/Financeapp.png'
import learnin from '../images/Learning.png'
import logistics from '../images/Logistics.png'
import medical from '../images/Medical.png'
import solution from '../images/solution.png'
import travel from '../images/travel.png'
import social from '../images/Social.png'
import realestate from '../images/relestate.png'
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import LanguageIcon from '@mui/icons-material/Language';
import BugReportIcon from '@mui/icons-material/BugReport';
import CurrencyBitcoinIcon from '@mui/icons-material/CurrencyBitcoin';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import CoPresentIcon from '@mui/icons-material/CoPresent';







const useStyles = makeStyles((theme) => ({
    customIconSizes: {
        fontSize: '75px',
    },
    editedcustomSize: {
      fontSize: '55px',
      marginTop: '15px',
      marginRight: '5px',
      marginLeft: '15px',
    },
    customSize: {
      fontSize: '30px',
    },
    socialIcon: {
        fontSize: '50px',
        marginLeft: '5px',
        marginRight: '15px',
    },
    serviceIcon: {
        marginBottom: '70px',
        marginRight: '0px',
    },
  }));


export const Products = () => {




    const classes = useStyles();
    const responsive = {
        superLargeDesktop: {
          breakpoint: { max: 4000, min: 3000 },
          items: 5
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 3
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        }
      };
    return(
        <div>
            <Container>
                <Row>
                    <Col className='jujuk'>
                        <h2 className='servhder'>
                            Industries We Serve
                        </h2>
                        <p className='servmintxt'>We make almost every genre of applications, You name it and we Build it</p>
                    </Col>
                </Row>
                <Row style={{marginBottom:'15px'}}>
                    <Col>
                        <Carousel responsive={responsive}>
                            <div><img src={blockchin} className='servpix'/><p className='servtxt'>Blockchain Dao's</p></div>
                            <div><img src={ecommerce} className='servpix' /><p className='servtxt'>Retail | Ecommerce</p></div>
                            <div><img src={learnin} className='servpix' /><p className='servtxt'>Education & e-learning</p></div>
                            <div><img src={medical} className='servpix' /><p className='servtxt'>Healthcare & Fitness</p></div>
                            <div><img src={social} className='servpix' /><p className='servtxt'>Social Networking</p></div>
                            <div><img src={realestate} className='servpix' /><p className='servtxt'>Real Estate</p></div>
                            <div><img src={travel} className='servpix' /><p className='servtxt'>Travel & Hospitality</p></div>
                            <div><img src={resturant} className='servpix' /><p className='servtxt'>Food & Resturant</p></div>
                            <div><img src={logistics} className='servpix' /><p className='servtxt'>Logistics & Distribution</p></div>
                            <div><img src={finance} className='servpix' /><p className='servtxt'>FinTech</p></div>
                            <div><img src={solution} className='servpix' /><p className='servtxt'>On-Demand Solutions</p></div>
                        </Carousel>
                    </Col>
                </Row>
                <Row className='rectngle'>
                    <Col>
                        <div className='mainservichldr'>
                            <h2>We Offer A Wide Variety of IT Services</h2>
                            <div className='servicehldr'>
                                <div>
                                    <div  className='minmainone'>
                                        <div><LanguageIcon style={{fontSize: '50px'}} className={classes.serviceIcon}/></div>
                                        <div className='mainvil'>
                                            <h3 className='midhder midhderweb'>Web Development</h3>
                                            <p className='minvil'>Our distinction goes beyond mere coding proficiency. It's our extensive experience that sets us apart in the field of web development.</p>
                                        </div>
                                    </div>
                                </div>
                                <div >
                                    <div  className='minmainone'>
                                        <div><PhoneIphoneIcon style={{fontSize: '50px'}} className={classes.serviceIcon}/></div>
                                        <div className='mainvil'>
                                            <h3 className='midhder midhdermbile'>Mobile Development</h3>
                                            <p className='minvil' >Build a robust brand with tailored mobile apps that meet market requirements. Develop enterprise software for reliable integration and legacy system modernization.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='servicehldr'>
                                <div >
                                    <div className='minmainone'>
                                        <div><CoPresentIcon  className={classes.serviceIcon} style={{fontSize: '50px'}}/></div>
                                        <div className='mainvil'>
                                            <h3 className='midhder midhderconsul' >IT Consultancy</h3>
                                            <p className='minvil'>Leverage our extensive IT expertise for efficient and cost-effective consulting. Trust our top minds to streamline workflows, implement new tech, and consolidate app portfolios.</p>
                                        </div>
                                    </div>
                                </div>
                                <div >
                                    <div  className='minmainone'>
                                        <div><CurrencyBitcoinIcon style={{fontSize: '50px'}} className={classes.serviceIcon}/></div>
                                        <div className='mainvil'>
                                            <h3 className='midhder midhderblkchn'>Blockchain Development</h3>
                                            <p className='minvil' >Create secure and transparent Blockchain applications. Dedicated to enhancing and building top-tier blockchain products for everyday use.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='servicehldr'>
                                <div  >
                                    <div  className='minmainone'>
                                        <div><BugReportIcon style={{fontSize: '50px'}} className={classes.serviceIcon}/></div>
                                        <div className='mainvil'>
                                            <h3 className='midhder midhderqatst' >QA & Testing</h3>
                                            <p className='minvil' >Count on our seasoned experts to conduct in-depth and thorough software testing and auditing, ensuring the reliability and quality of your software solutions.</p>
                                        </div>
                                    </div>
                                </div>
                                <div >
                                    <div className='minmainone'>
                                        <div><DesignServicesIcon style={{fontSize: '50px'}} className={classes.serviceIcon}/></div>
                                        <div className='mainvil'>
                                            <h3 className='midhder midhderdsgn'>Design Solutions</h3>
                                            <p className='minvil'>We excel in crafting standout, user-friendly software. Our UI/UX designers specialize in creating brand identity concepts and exceptional interfaces.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}