import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import styled, { keyframes } from 'styled-components';
import Row from 'react-bootstrap/Row';
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ideass from '../images/ideass.jpg'
import ceooo from '../images/cep.jpg'
import kene from '../images/profile.png'
import nell from '../images/noelpfp.png'





export const AboutQpidProfessionals = () => {
    return(
        <div>
            <Container>
                <Row>
                    <Col>
                        <div style={{marginTop:'95px', textAlign:'left', width:'150px'}}><h2 className='abthder'>About Us</h2></div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className='abtmainhldr'>
                            <div><p className='abttxtmai'>Welcome to Qpid's Professionals — your go-to for technology solutions. Specializing in blockchain, web/mobile app design/development, and media services, we're your dynamic software partner. Our end-to-end solutions transform ideas into reality.</p>

                            <p className='abttxtmai'>Simplify business with our intelligent tech, streamlining tasks for enhanced efficiency and profits. Let us handle the technical side, allowing you to focus on the fun of running your business.

                            Choose Qpids Professionals for unbeatable mobile app development and IT solutions. Our Elite team crafts innovative apps and websites, delivering growth without compromising time and quality.</p>
                            </div>
                            <div><img src={ideass} className='abtpix'/></div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div><h2 className='elithder'>Qpid's Elite Team</h2></div>
                    </Col>
                </Row>
                <Row>
                    <Col className='elitgath'>
                        <div className='temhlder'>
                            <div className='tempixhlder'><img src={nell} className='tempix' /></div>
                            <p className='temname'>Noel.E.Ose</p>
                            <p className='temtitle'>Managing Director (Python/Blockchain Developer)</p>
                        </div>
                        <div className='temhlder'>
                            <div className='tempixhlder'><img src={ceooo} className='tempix' /></div>
                            <p className='temname'>Bonaventure Edetan</p>
                            <p className='temtitle'>Fullstack PHP Developer</p>
                        </div>
                        <div className='temhlder'>
                            <div className='tempixhlder'><img src={kene} className='tempix' /></div>
                            <p className='temname'>Kenechukwu Mbanefo</p>
                            <p className='temtitle'>Chief Technology Officer (Mobile Developer)</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}