import './App.css';
import { Header } from './components/header';
import { Main } from './components/main';
import { AboutQpidProfessionals } from './components/about';
import { Products } from './components/products';
import { ContactUs } from './components/contacts';
import { createBrowserRouter, Route, createRoutesFromElements, RouterProvider } from 'react-router-dom';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path='/' element={<Header/>}>
      <Route index element= {<Main/>}/>
      <Route path='AboutQpidProfessionals' element={<AboutQpidProfessionals/>}/>
      <Route path='Services' element={<Products/>}/>
      <Route path='Contact' element={<ContactUs/>}/>
    </Route>
  )
)

function App() {
  return (
    <div className="App">
      <div>
        <RouterProvider router={router}/>
      </div>
    </div>
  );
}

export default App;
