import {Outlet, NavLink, Link} from "react-router-dom"
import { Footer } from "./footer"
import logomain from "../images/qpin.png"
import extenson from "../images/Prof.jpg"
import { useState } from "react"
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';


export const Header = () => {

    const [active, setActive] = useState('dropdwn')
    const [wrking, setwrking] = useState('mainnavlink navitem')
    const [togglerIcon, settogglerIcon] = useState('menubtn')
    const navtoggle = () => {
        active === 'dropdwn' ? setActive('dropdwn dropdwnactive') : setActive('dropdwn')
        wrking === 'mainnavlink navitem' ? setwrking('mainnavlink navitem mainnavlinkactive') : setwrking('mainnavlink navitem')
        togglerIcon === 'menubtn' ? settogglerIcon('menubtn toggle') : settogglerIcon('menubtn')
    }
    

    return(
        <div>
            <div className="originadiv">
                <div className="mainnav">
                    <div>
                        <Link to="/">
                            <img src={logomain} className="mainlogo" alt="Qpid's Tech" />
                            <img src={extenson} className="midlogo" alt="Qpid's Professionals"/>
                        </Link>
                    </div>
                    <div className="midnav">
                        <div  className={active}>
                            <Link to="/" ><button className={wrking}>Home</button></Link>
                            <Link to="Services" ><button className={wrking}>Services</button></Link>
                            <Link to="AboutQpidProfessionals" ><button className={wrking}>About</button></Link>
                            <Link to="Contact"><button className={wrking}>Contact</button></Link>
                        </div>
                        <div onClick={navtoggle} className={togglerIcon} style={{marginRight:'5px'}}>
                            <div className="linetog line1"></div>
                            <div className="linetog line2"></div>
                            <div className="linetog line3"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <Outlet/>
            </div>
            <div>
                <Footer/>
            </div>
        </div>
    )
}