import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Link} from "react-router-dom"

export const Footer = () => {
    return(
        <div>
            <Container>
                    <footer>
                        <div>
                            <Row>
                                <Col>
                                    <div>
                                    </div>
                                    <div></div>
                                </Col>
                                <Col></Col>
                            </Row>
                        </div>
                        <div className='testblnk'></div>
                        <p>&copy; Qpids Elite Technologies 2022 | | All Rights Reserved.</p>
                        <div className='testblnk'></div>
                    </footer>
            </Container>
        </div>
    )
}