import React, {FormEvent, useRef } from 'react';
import emailjs from '@emailjs/browser';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import EmailIcon from '@mui/icons-material/Email';
import SendIcon from '@mui/icons-material/Send';
import { makeStyles } from '@material-ui/core/styles';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import PhoneEnabledIcon from '@mui/icons-material/PhoneEnabled';
import Emailcampaing from '../images/Emailcampaing.png'


const useStyles = makeStyles((theme) => ({
    customIconSizes: {
        fontSize: '75px',
    },
    editedcustomSize: {
      fontSize: '55px',
      marginTop: '15px',
      marginRight: '5px',
      marginLeft: '15px',
    },
    customSize: {
      fontSize: '30px',
    },
    socialIcon: {
        fontSize: '50px',
        marginLeft: '5px',
        marginRight: '15px',
    },
    serviceIcon: {
        marginBottom: '70px',
        marginRight: '0px',
    },
  }));




export const ContactUs = () => {

    const classes = useStyles();


    const form = useRef();

    const sendEmailfrm = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_cjv9jr5', 'template_wiv3y9r', form.current, 'D-H7wbWhWoPa_n4ED')
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });
    };



    return(
        <div>
            <Container>
                <Row>
                    <Col style={{textAlign:'left'}} className='conthder'>
                        <div style={{ width:'190px'}}><h2 className='abthder'>Contact Us</h2></div>
                    </Col>
                </Row>
                <Row>
                    <Col className='cntbdy'>
                        <div><img src={Emailcampaing} className='cntpic'/></div>
                        <div><h3 style={{color:'white', fontFamily:'monospace'}}>Leave us a Message!</h3></div>
                        <div className='frmhldere'>
                            <form ref={form} onSubmit={sendEmailfrm} className='frmbdycnt'>
                                <label className='cnttxtlbl'>Name</label>
                                <input type="text" name="user_namne"  className='nmeput'/>
                                <label className='cnttxtlbl'>Email</label>
                                <input type="email" name="user_emaill" className='emailput'/>
                                <label className='cnttxtlbl'>Message</label>
                                <textarea name="messsage" className='areaput'/>
                                <input type="submit" value="Send" className='btnput'/>
                            </form>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
